<template>
  <div>

    <section>      
      <b-container class="mt-5 mb-5 pt-2">

        <b-row class="mb-4">
          <b-col>
            <h1>Directorio</h1>
          </b-col>
        </b-row>


        <b-row class="mb-4">
          <b-col v-for="(item,index) in chiefs" :key="item.id" lg="3" md="3" sm="6" xs="12">
            <div class="card-directory m-1 grey lighten-4 box-10 z-depth-2 text-center">

              <b-img
                fluid-grow
                thumbnail
                :src="`${ path.storage_files + item.photo.url }`"
                :alt="`${ item.fullname }`"
                :title="`${ item.fullname }`"
                rounded="circle"
                class="z-depth-3 p-2"
              ></b-img>

              <h4 class="mt-3 mb-0">{{item.short_name }}</h4>
              <small class="small text-muted">{{ item.job }}</small>

              <div class="mt-3">
                <b-button
                v-if="item.twitter"
                  pill
                   size="sm"
                  class="m-1"
                  variant="info"
                  :href="`https://twitter.com/${item.twitter}`" target="_blank"
                  v-b-tooltip.hover title="Twitter"
                >
                  <i class="fa-brands fa-twitter"></i>
                </b-button>
                <b-button
                  pill
                   size="sm"
                  class="m-1"
                  variant="danger"
                  :href="`mailto:${item.email}@sre.gob.mx`"
                  v-b-tooltip.hover title="Correo electronico"
                >
                  <i class="fa-solid fa-envelope"></i>
                </b-button>
                <b-button
                  pill
                   size="sm"
                  class="m-1"
                  variant="success"
                  @click="showSemblance(index)"
                  v-b-tooltip.hover title="Semblanza"
                >
                  <i class="fa-solid fa-signature"></i>
                </b-button>
              </div>
              <b-button 
                pill 
                size="sm" 
                class="m-2" 
                variant="dark" 
                :href="`tel:+525536865100,,${item.ext}`"
              >
                <i class="fa-solid fa-phone"></i>
                +52 55 3686 5100 Ext. {{ item.ext }}
              </b-button>

            </div>
          </b-col>
        </b-row>

        <b-row v-show="semblance === true" class="mt-5 mb-5 card-directory m-1 grey lighten-4 box-10 z-depth-2">
          <b-col v-for="(item,index) in chiefs" :key="index" lg="12">
            <div v-if="semblanceId === index">
              <h5 class="grey-text uppercase">Semblanza</h5>
              <h1>
                {{item.fullname}}
                <b-button
                  class="float-right"
                  variant="danger"
                  @click="hideSemblance()"
                  v-b-tooltip.hover title="Cerrar"
                >
                  <i class="fa-solid fa-close"></i>
                </b-button>
                <hr class="divisor" />
              </h1>
              <vue-simple-markdown :source="`${item.semblance}`"></vue-simple-markdown>


              <div class="mt-3">
                <b-button
                v-if="item.twitter"
                  class="m-1 white-text"
                  variant="info"
                  :href="`https://twitter.com/${item.twitter}`" target="_blank"
                  v-b-tooltip.hover title="Twitter"
                >
                  <i class="fa-brands fa-twitter"></i>
                  @{{item.twitter}}
                </b-button>
                <b-button
                  class="m-1"
                  variant="danger"
                  :href="`mailto:${item.email}@sre.gob.mx`"
                  v-b-tooltip.hover title="Correo electronico"
                >
                  <i class="fa-solid fa-envelope"></i>
                  {{item.email}}@sre.gob.mx
                </b-button>
                <b-button 
 
                  class="m-2" 
                  variant="dark" 
                  :href="`tel:+525536865100,,${item.ext}`"
                >
                  <i class="fa-solid fa-phone"></i>
                  +52 55 3686 5100 Ext. {{ item.ext }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>


        <b-row class="mt-5 mb-5">
          <b-col>
            <h3>Oficina  del Titular</h3>
            <b-row>
              <b-col v-for="item in directionGeneral" :key="item.id" lg="4" md="4" sm="6" xs="12">
                <div class="card-directory m-1 grey lighten-4 box-10 z-depth-2 text-center" >
                  <h5>{{ item.fullname }}</h5>
                  <small class="small text-muted">{{ item.job }}</small><br />
                  <b-button pill  size="sm" class="m-1" variant="danger" :href="`mailto:${item.email}@sre.gob.mx`" v-b-tooltip.hover title="Correo electronico">
                    <i class="fa-solid fa-envelope"></i>
                  </b-button>
                  <b-button pill size="sm" class="m-2" variant="dark" :href="`tel:+525536865100,,${item.ext}`" v-b-tooltip.hover :title="`Extención: ${item.ext}`">
                    <i class="fa-solid fa-phone"></i>
                    +52 55 3686 5100 Ext. {{ item.ext }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-5 mb-5">
          <b-col>
            <h3>Dirección Ejecutiva A</h3>
            <b-row>
              <b-col v-for="item in executiveDirectionA" :key="item.id" lg="4" md="4" sm="6" xs="12">
                <div class="card-directory m-1 grey lighten-4 box-10 z-depth-2 text-center" >
                  <h5>{{ item.fullname }}</h5>
                  <small class="small text-muted">{{ item.job }}</small><br />
                  <b-button pill  size="sm" class="m-1" variant="danger" :href="`mailto:${item.email}@sre.gob.mx`" v-b-tooltip.hover title="Correo electronico">
                    <i class="fa-solid fa-envelope"></i>
                  </b-button>
                  <b-button pill size="sm" class="m-2" variant="dark" :href="`tel:+525536865100,,${item.ext}`" v-b-tooltip.hover :title="`Extención: ${item.ext}`">
                    <i class="fa-solid fa-phone"></i>
                    +52 55 3686 5100 Ext. {{ item.ext }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-5 mb-5">
          <b-col>
            <h3>Dirección Ejecutiva B</h3>
            <b-row>
              <b-col v-for="item in executiveDirectionB" :key="item.id" lg="4" md="4" sm="6" xs="12">
                <div class="card-directory m-1 grey lighten-4 box-10 z-depth-2 text-center" >
                  <h5>{{ item.fullname }}</h5>
                  <small class="small text-muted">{{ item.job }}</small><br />
                  <b-button pill  size="sm" class="m-1" variant="danger" :href="`mailto:${item.email}@sre.gob.mx`" v-b-tooltip.hover title="Correo electronico">
                    <i class="fa-solid fa-envelope"></i>
                  </b-button>
                  <b-button pill size="sm" class="m-2" variant="dark" :href="`tel:+525536865100,,${item.ext}`" v-b-tooltip.hover :title="`Extención: ${item.ext}`">
                    <i class="fa-solid fa-phone"></i>
                    +52 55 3686 5100 Ext. {{ item.ext }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-container>
    </section>
  </div>
</template>

<script>
import DataDirectoryPage from "@/services/data-directory";
import HeaderCategory from '@/components/Custom/Layout/HeaderCategory.vue';

export default {
  name: 'HomePage',

  metaInfo: {
  title: 'Instituto de los Mexicanos en el Exterior',
  titleTemplate: null
},

  components: {
    DataDirectoryPage,
    HeaderCategory,
  },

  data() {
    return {

      chiefs: [],
      directionGeneral: [],
      executiveDirectionA: [],
      executiveDirectionB: [],

      semblance: false,
      semblanceId: null,
      semblanceJulietaM: false,
      semblanceRSantana: false,
      semblanceCCalvo: false,

      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },
    
    }
  },

  mounted() {
    document.title = 'Instituto de los Mexicanos en el Exterior';
    this.directoryChiefs();
    this.directoryItems();
    this.directoryDirectionGeneral();
    this.directoryExecutiveDirectionA();
    this.directoryExecutiveDirectionB();
  },

  methods: {

    directoryChiefs() {
      DataDirectoryPage.getPersonalChiefs()
        .then(response => {
          this.chiefs = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    directoryItems() {
      DataDirectoryPage.getAllDirectory()
        .then(response => {
          this.directories = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    directoryDirectionGeneral() {
      DataDirectoryPage.getPersonalDirectionGeneral()
        .then(response => {
          this.directionGeneral = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    directoryExecutiveDirectionA() {
      DataDirectoryPage.getPersonalExecutiveDirectionA()
        .then(response => {
          this.executiveDirectionA = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },

    directoryExecutiveDirectionB() {
      DataDirectoryPage.getPersonalExecutiveDirectionB()
        .then(response => {
          this.executiveDirectionB = response.data;
        })
        .catch(e => {
          console.log(e);
        });
    },


    showSemblance(id) {
      this.semblance = true;
      this.semblanceId = id;
    },
    hideSemblance() {
      this.semblance = false;
    },


  },


}
</script>