import http from "@/services/http-common";

class DataDirectoryPage {

    getDirectory() {
        return http.get('/directory/');
    }

    getAllDirectory() {
        return http.get('/personals/');
    }

    getPersonal(id) {
        return http.get(`/personals/${id}`);
    }

    getPersonalChiefs() {
        return http.get(`/personals/?level=1&level=2&level=9`);
    }

    getPersonalDirectionGeneral() {
        return http.get(`/personals/?area=direccion_general`);
    }

    getPersonalExecutiveDirectionA() {
        return http.get(`/personals/?area=direccion_ejecutiva_a`);
    }

    getPersonalExecutiveDirectionB() {
        return http.get(`/personals/?area=direccion_ejecutiva_b`);
    }

}

export default new DataDirectoryPage();
